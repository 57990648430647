import React from "react";
import { IconContext } from "@react-icons/all-files";
import { FaLongArrowAltRight } from "@react-icons/all-files/fa/FaLongArrowAltRight";
import styles from "../../styles/timeline.module.scss";
import EXPERIENCE_TIMELINE from "../../data/experienceTimeline";
import cleanHtml from "../../utils/cleanHtml";

export default function Timeline() {
  return (
    <div className={ styles.timeline }>

      {/* LEFT -- LINE */}
      <div className={ styles.timeline__left }>
        <div className={ styles.line }></div>
      </div>

      {/* RIGHT -- CONTENT */}
      <div className={ styles.timeline__right }>

        { EXPERIENCE_TIMELINE.map((experience) => {
          return (
            <div className={ styles.timeline__container } key={experience.id}>
              <div className={ styles.dot } />
              <div className={ styles.content }>
                <div 
                  className={ styles.date }
                  dangerouslySetInnerHTML={{ __html: cleanHtml(experience.date) }}
                />
                <div>
                  {experience.roles.map((role) => {
                    return (
                      <>
                        <div
                          key={role}
                          className={ styles.role }
                          dangerouslySetInnerHTML={{ __html: cleanHtml(role) }}
                        />
                      </>
                    );
                  })}
                </div>
                <div className={ styles.description }>
                  <div 
                    className={styles.description__body}
                    dangerouslySetInnerHTML={{ __html: cleanHtml(experience.description) }}
                  />
                  { experience.learnMore && (
                    <div className={styles.description__footer}>
                      <a className={styles.link} target="_blank" rel="noreferrer noopener" href={experience.learnMore}>
                        Learn More
                        <IconContext.Provider value={{ size: "20px" }}>
                          <FaLongArrowAltRight className={ `icon__right` } />
                        </IconContext.Provider>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}


        {/* END */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ `${styles.date} ${styles.date__end}` }>The Beginning</div>
          </div>
        </div>
      </div>
    </div>
  )
};
